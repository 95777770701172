import React, { useState } from 'react'
import { getTop10 } from '../../helpers/getTop10';
import { getTop10Total } from '../../helpers/getTop10Total';
import { getTopCliente } from '../../helpers/getTopCliente';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import { DateRange } from 'react-date-range';
import { getTop10Xfecha } from '../../helpers/getTop10Xfecha';
import { getTop10Totalfecha } from '../../helpers/getTop10Totalfecha';
import { getTopClienteFecha } from '../../helpers/getTopClienteFecha';

export const SelectFecha = ({ setTiempo, tienda, area, setTartcantidad, setTarttotal, 
  setTclitotal}) => {

    const [fecha, setFecha] = useState({
      colordiv: 'light'
    });
    const [daterango, setDaterango] = useState([
      {    
        startDate: new Date(),
        endDate: null,
        key: 'selection',
      }
    ]);
    const crearOption = (valor) => {
      switch (valor) {
        case 1:
          setTiempo(1);
          break;
        case 2:
          setTiempo(2);
          break;
        case 3:
          setTiempo(3);
          break;
        default:
          break;
      }
      
      if (valor == 0) {
        var fecha1 = convert(daterango[0].startDate);
        var fecha2 = convert(daterango[0].endDate);

        getTop10Xfecha(fecha1, fecha2, tienda, area).then(e => {
          setTartcantidad({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })
        });
        getTop10Totalfecha(fecha1, fecha2, tienda, area).then(e => {
          setTarttotal({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })   
        });
        getTopClienteFecha(fecha1, fecha2, tienda, area).then(e => {
          setTclitotal({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })
        });
      } else {
        getTop10(valor, tienda, area).then(e => {
          setTartcantidad({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })
        });
        getTop10Total(valor, tienda, area).then(e => {
          setTarttotal({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })
        });
        getTopCliente(valor, tienda, area).then(e => {
          setTclitotal({
            serie: e.top10.serie,
            option: { labels: e.top10.option}
          })
        });
      }
    }
    const clickFecha = (e) => {
        var elem = document.getElementById(e.target.id);
        setFecha({
          colordiv: 'light'
        })
        document.getElementById('divFecha').style.backgroundColor = 'transparent';
        document.getElementById('divFecha').style.color = 'grey';
        switch (e.target.id) {
          case 'divmes':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divhoy').style.backgroundColor = 'transparent';
            document.getElementById('divsemana').style.backgroundColor = 'transparent';
            document.getElementById('divhoy').style.color = 'grey';
            document.getElementById('divsemana').style.color = 'grey';
            crearOption(3);
  
            break;
          case 'divhoy':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divmes').style.backgroundColor = 'transparent';
            document.getElementById('divsemana').style.backgroundColor = 'transparent';
            document.getElementById('divmes').style.color = 'grey';
            document.getElementById('divsemana').style.color = 'grey';
  
            crearOption(1);
  
            break;
          case 'divsemana':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divhoy').style.backgroundColor = 'transparent';
            document.getElementById('divmes').style.backgroundColor = 'transparent';
            document.getElementById('divhoy').style.color = 'grey';
            document.getElementById('divmes').style.color = 'grey';
  
            crearOption(2);
  
            break;
            default:
            break;
        }
    }
    const clickColorFecha = (e) => {
      crearOption(0);
      setFecha({
        colordiv: 'info'
      })
      document.getElementById('divmes').style.backgroundColor = 'transparent';
      document.getElementById('divsemana').style.backgroundColor = 'transparent';
      document.getElementById('divhoy').style.backgroundColor = 'transparent';
  
      document.getElementById('divhoy').style.color = 'grey';
      document.getElementById('divmes').style.color = 'grey';
      document.getElementById('divsemana').style.color = 'grey';
    }
    const popover = (
      <div className='border'>
          <DateRange editableDateInputs={true} 
          onChange={item => setDaterango([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={daterango} />
      </div>
    );
    function convert(str) {
      var date = new Date(str), 
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("-");
    }
  return (
    <>
        <div className='p-2 border colorazul HoySemanaMes' id='divhoy' 
        onClick={ clickFecha }>Hoy</div>
        <div className='p-2 border HoySemanaMes' id='divsemana' 
        onClick={ clickFecha }>Semana</div>
        <div className='p-2 border HoySemanaMes' id='divmes' onClick={ clickFecha }>Mes</div>
        <div id="divFecha" className='border' onClick={ clickColorFecha }>
          <OverlayTrigger 
            trigger="click" 
            placement='bottom' 
            rootClose={true}
            overlay={popover}>                    
              <Button variant={fecha.colordiv}>
                <FontAwesomeIcon icon="fa-solid fa-calendar-days" size='1x' />
              </Button>
          </OverlayTrigger>
        </div>
    </>
  )
}
