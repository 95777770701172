import React, { useRef } from 'react'
import { useAuth } from '../../auth'
import { setUsuario } from '../../helpers/setUsuario';

export const Profile = () => {

    const ref = useRef(null);

    const auth = useAuth();

    const guardar = (e) => {
        e.preventDefault();
        setUsuario(auth.user.cdusuario, ref.current.value)
    }
  return (
    <div>
        <h3>
            {auth.user.dsusuario}
        </h3>
        <br />
        <div>Cambiar Contraseña</div>
        <input ref={ref} type={'password'}></input>
        <button onClick={guardar}>OK</button>
    </div>
  )
}
