import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TotalEfectivo = ({ efectivo }) => {

    const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = efectivo.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentaje');
    var elem1 = document.getElementById('iconPorc');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }
    return (
        <>
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={ 'fa-solid fa-money-bill-1' } 
                                size='2x' color='green'/>
                            </div>
                            <div className="col valortotalpasado2 d-flex align-items-center">
                                Total de ventas en efectivo
                            </div>
                        </div>
                        <div className="totalIndicador">
                            { formatterSoles.format(efectivo.total) }
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <div className="valortotalpasado">{ efectivo.label }</div>
                                <div className="valortotalpasado2">
                                    { formatterSoles.format(efectivo.totalAyer) }
                                </div>
                            </div>
                            <div className="col align-items-center">
                                <div className="row">
                                    <div id="idPorcentaje" 
                                    className="fw-bolder fs-4 d-flex justify-content-end col">
                                        { efectivo.porcentaje }
                                    </div>
                                    <div className="col-auto rounded d-flex align-items-center"
                                    id='iconPorc'>
                                        <FontAwesomeIcon icon={ efectivo.icon } 
                                        color='white'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// style={{backgroundColor: '#ff4d4d'}}