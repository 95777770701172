import { useNavigate, Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useAuth } from '../auth';


export const Navbara = () => {

    const auth = useAuth();

    const navigate = useNavigate();

    const onLogout = () => {
        localStorage.setItem('authed', null);
        navigate('/login', { replace: true });
    }
    const goProfile = () => {
        navigate('/profile')
    }
    // navbar-light" style={{ backgroundColor: '#07A1E4'
    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-info">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/">
               Ventas
            </Link>
          <button className="navbar-toggler" type="button" 
            data-bs-toggle="collapse" data-bs-target="#navbarNav" 
            aria-controls="navbarNav" aria-expanded="false" 
            aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <NavLink className={ ({isActive}) => 
                    `nav-item nav-link ${ isActive ? 'active':'' }` } 
                    to="/top10">
                    top 10
                </NavLink>
                <NavLink className={ ({isActive}) => 
                    `nav-item nav-link ${ isActive ? 'active':'' }` } 
                    to="/grupo">
                    grupos
                </NavLink>
                <NavLink className={ ({isActive}) => 
                    `nav-item nav-link ${ isActive ? 'active':'' }` } 
                    to="/Resumen">
                    resumen
                </NavLink> */}
            </ul>
            <form class="d-flex">
                <button className='btn btn-success' onClick={onLogout}>Salir</button>
            </form>
          </div>
        </div>
      </nav>

    )
}

