import React from 'react'
import Chart from "react-apexcharts";

export const ArtCantidad = ({tartcantidad}) => {
  return (
    <>
    <div className='pt-3 ps-3'>Articulos mas vendidos</div>
      <div id='chart' className='w-auto'>
        <Chart
          options={tartcantidad.option}
          series={tartcantidad.serie}
          type="donut"
          width="500"
          // height="300"
        />
      </div>
    </>
  )
}