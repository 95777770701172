export const getFacturas = async(parametro, tienda, area, cdprecio) => {
    const totales = [];
    let url = '';
    if (tienda == "" || tienda == '00') tienda = "00";
    if (area == "" || area == '00') area = "00";
    if (cdprecio == "" || cdprecio == '00') cdprecio = "00";
    console.log(parametro);
    url = 
    `https://apiflavorcup.zpinfosac.com/api/facturadashboards/param/
    ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apigranja.zigmaperu.com/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apidashdanie.zigmaperu.com/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdurabilis.zigmaperu.com/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashboard.g11sac.com/api/factura/param/
    // ${ encodeURI(parametro)}/${encodeURI(tienda)}/${encodeURI(area)}/${encodeURI(cdprecio)}`
    // `https://localhost:7109/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${encodeURI(cdprecio)}`
    // `https://apichozanautica.zigmaperu.com/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    //    `https://apicordonylarosa.zigmaperu.com/api/factura/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://localhost:7249/api/facturadashboards/param/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`

    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}


    // `https://localhost:7109/api/factura/param/
    // /${ encodeURI(area)}/


    