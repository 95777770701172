export const getTop5Grupo = async(parametro, tienda, area) => {

    if (tienda == "" || tienda == '00') tienda = " ";
    if (area == "" || area == '00') area = " ";

    let url = '';
    url = 
    // `https://apiflavorcup.zigmaperu.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apigranja.zigmaperu.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdanie.zigmaperu.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdurabilis.zigmaperu.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashboard.g11sac.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apichozanautica.zigmaperu.com/api/factura/top5grupo/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    `https://apicordonylarosa.zigmaperu.com/api/factura/top5grupo/
    ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`


    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    console.log(factura);
    return factura;
}


