import React, { useReducer, useState } from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { createContext } from 'react'
// import { AuthContext } from './AuthContext'
import { authReducer } from './authReducer'

// const initialState = {
//     logged: false,
// }

// export const AuthProvider = ( children ) => {

//   const [ state, dispatch ] = useReducer( authReducer, initialState);
//   return (
//     <AuthContext.Provider value={{  }} >
//         { children }
//     </AuthContext.Provider>
//   )
// }

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(() => {
    var usuario = JSON.parse(localStorage.getItem('authed'));
    if (usuario == null) {
      return null;
    }
    else{
      if (usuario.cdusuario == '0' || localStorage.getItem('authed') == null) {
        return null;
      }
    }
    // var usuario = JSON.parse(localStorage.getItem('authed'));
    return {
      cdusuario : usuario.cdusuario,
      dsusuario : usuario.dsusuario,
      pass : ""
    };
  }
    // null
    // localStorage.getItem('authed') != null ? localStorage.getItem('authed') : null
    // () => localStorage.getItem('authed') !== null 
  //   {
  //   cdusuario : 0,
  //   dsusuario : "",
  //   pass : ""
  // }
  )

  useEffect(() => {
    localStorage.setItem('authed', JSON.stringify(user));
  }, [user])
  

  const login = (user) => {
    setUser(user);
    localStorage.setItem('authed', JSON.stringify(user));
  }

  const logout = () => {
    localStorage.setItem('authed', null);
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}