import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Totalventas = ({ total, icons }) => {

    var valor = total.porcentaje.substring(0, 1);
    var elem = document.getElementById('colorporc');

    if (elem != null) {
        if (valor == '+') {
            elem.style.backgroundColor = '#58C347';
        } else {
            elem.style.backgroundColor = '#ff4d4d';
        }
    }

  return (
      <>
        <div className='valortotalpasado2'>
            { total.label1 }
        </div>
        <div id='valortotal' className='text-center'>
            { total.total }
        </div>
        <div className='row justify-content-start pb-4'>
            <div className='col-4 text-end valortotalpasado'>
            { total.label2 }
            </div>
            <div className='col valortotalpasado2'>
            { total.totalPasado }
            </div>
        </div>
        <div className='row justify-content-center mx-1' id='colorporc'>
            <div className='col-3 text-start py-4'>
                <FontAwesomeIcon icon={ icons } id='iconoAumento' 
                size='4x' color='white'/>
            </div>
            <div className='col-7'>
                <div className='text-end pt-3' id='porcentaje'>
                    { total.porcentaje }
                </div>
                <div className='text-end' id='porcentajeaumento'>
                    { total.resta }
                </div>
            </div>
        </div>
      </>
  )
}