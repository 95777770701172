import React from 'react'
import Chart from "react-apexcharts";

export const Grupotop5 = ({ optiontop5 }) => {

  let serie = [{
    name: 'Servings',
    data: optiontop5.serie
  }]
let option = {
  chart: {
    height: 350,
    type: 'bar',
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '50%',
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 2
  },
  
  grid: {
    row: {
      colors: ['#fff', '#f2f2f2']
    }
  },
  xaxis: {
    labels: {
      rotate: -45
    },
    categories: optiontop5.option,
    tickPlacement: 'on'
  },
  yaxis: {
    title: {
      text: 'Totales',
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: "horizontal",
      shadeIntensity: 0.25,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 0.85,
      opacityTo: 0.85,
      stops: [50, 0, 100]
    },
  }
}
  

  return (
    <>
      <div>Categoria top 5</div>
      <div id='chart'>
          <Chart id = "apexchart"
              options={option}
              series={serie}
              type="bar"
              height="300"
          />
      </div>
    </>
  )
}
