import React from 'react'
import Chart from "react-apexcharts";

export const ArtTotal = ({tarttotal}) => {

  return (
    <>
        <div className='pt-3 ps-3'>Articulos con mayores ingresos</div>
        <div id='chart' className='w-auto'>
          <Chart
            options={tarttotal.option}
            series={tarttotal.serie}
            // options= {options}
            // series= {series}
            type="donut"
            width="500"
            // height="300"
          />
        </div>
    </>
  )
}
