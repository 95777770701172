export const getTopCliente = async (parametro, tienda, area) => {
    const totales = [];
    let url = '';
    // if (tienda == '') {
    //     area = '';
    // }
    if (tienda == "" || tienda == '00') tienda = " ";
    if (area == "" || area == '00') area = " ";

    url = 
    // `https://apiflavorcup.zigmaperu.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apigranja.zigmaperu.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdanie.zigmaperu.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdurabilis.zigmaperu.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashboard.g11sac.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apichozanautica.zigmaperu.com/api/factura/top10cliente/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
   `https://apicordonylarosa.zigmaperu.com/api/factura/top10cliente/
    ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    
    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}


