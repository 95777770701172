import React, { useState } from 'react'
import { getArticuloEdit } from '../../helpers/getArticuloEdit';
import { getTop5Grupo } from '../../helpers/getTop5Grupo';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { DateRange } from 'react-date-range';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTop5GrupoXfecha } from '../../helpers/getTop5GrupoXfecha';

export const SelectFecha = ({setTiempo, tienda, area, setOptiontop5, setArticuloedit}) => {

  const [daterango, setDaterango] = useState([
    {    
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);

    const crearOption = (valor) => {
        switch (valor) {
            case 1:
            setTiempo(1);
            break;
            case 2:
            setTiempo(2);
            break;
            case 3:
            setTiempo(3);
            break;
            default:
            break;
        }
        function sum(total, curr) {
            return total + curr;
        }
        if (valor == 0) {
          var fecha1 = convert(daterango[0].startDate);
          var fecha2 = convert(daterango[0].endDate);
          
          // console.log(fecha1);
          // console.log(fecha2);

          getTop5GrupoXfecha(fecha1, fecha2, "", "").then(e => {
            e.top10.option.push('Total');
            e.top10.serie.push(e.top10.serie.reduce(sum, 0));
            setOptiontop5({
              serie: e.top10.serie,
              option: e.top10.option
            })
          });          
        } else {
          getTop5Grupo(valor, "", "").then(e => {
            e.top10.option.push('Total');
            e.top10.serie.push(e.top10.serie.reduce(sum, 0));
            setOptiontop5({
              serie: e.top10.serie,
              option: e.top10.option
            })
          });
          getArticuloEdit(valor, "", "").then(e => {
            // e.top10.option.push('Total');
            // e.top10.serie.push(e.top10.serie.reduce(sum, 0));
            setArticuloedit({
              serie1: e.top10.serie,
              option1: e.top10.option
            })
          });
        }
    }

    const clickFecha = (e) => {
        var elem = document.getElementById(e.target.id);
        switch (e.target.id) {
            case 'divmes':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divhoy').style.backgroundColor = 'transparent';
            document.getElementById('divsemana').style.backgroundColor = 'transparent';
            document.getElementById('divhoy').style.color = 'grey';
            document.getElementById('divsemana').style.color = 'grey';
            crearOption(3);

            break;
            case 'divhoy':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divmes').style.backgroundColor = 'transparent';
            document.getElementById('divsemana').style.backgroundColor = 'transparent';
            document.getElementById('divmes').style.color = 'grey';
            document.getElementById('divsemana').style.color = 'grey';

            crearOption(1);

            break;
            case 'divsemana':
            elem.style.backgroundColor = '#2BC1EE';
            elem.style.color = 'white';
            document.getElementById('divhoy').style.backgroundColor = 'transparent';
            document.getElementById('divmes').style.backgroundColor = 'transparent';
            document.getElementById('divhoy').style.color = 'grey';
            document.getElementById('divmes').style.color = 'grey';

            crearOption(2);

            break;
            default:
            break;
        }
    }

    const clickColorFecha = (e) => {
      crearOption(0);
      setFecha({
        colordiv: 'info'
      })
      // console.log(daterango);
      document.getElementById('divmes').style.backgroundColor = 'transparent';
      document.getElementById('divsemana').style.backgroundColor = 'transparent';
      document.getElementById('divhoy').style.backgroundColor = 'transparent';
  
      document.getElementById('divhoy').style.color = 'grey';
      document.getElementById('divmes').style.color = 'grey';
      document.getElementById('divsemana').style.color = 'grey';
    }
    const popover = (
      <div className='border'>
          <DateRange editableDateInputs={true} 
          onChange={item => setDaterango([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={daterango} 
          minDate={new Date("02-11 -2023")}/>
      </div>
    );
    const [fecha, setFecha] = useState({
      colordiv: 'light'
    })
    function convert(str) {
      var date = new Date(str), 
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("-");
    }
  return (
    <>
        <div className='p-2 border colorazul HoySemanaMes' id='divhoy' 
        onClick={ clickFecha }>Hoy</div>
        <div className='p-2 border HoySemanaMes' id='divsemana' 
        onClick={ clickFecha }>Semana</div>
        <div className='p-2 border HoySemanaMes' id='divmes' onClick={ clickFecha }>Mes</div>
        <div id="divFecha" className='border' onClick={ clickColorFecha }>
          <OverlayTrigger 
            trigger="click" 
            placement='bottom' 
            rootClose={true}
            overlay={popover}>
              <Button variant={fecha.colordiv}>
                <FontAwesomeIcon icon="fa-solid fa-calendar-days" size='1x' />
              </Button>
          </OverlayTrigger>
        </div>
    </>
  )
}
