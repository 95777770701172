import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TotalCredito = ({ credito }) => {

    const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = credito.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentajeCred');
    var elem1 = document.getElementById('iconPorcCred');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }
  return (
    <div className="col">
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-auto">
                        <FontAwesomeIcon icon={ 'fa-solid fa-file-invoice-dollar' } 
                        size='2x' color='purple'/>
                    </div>
                    <div className="col valortotalpasado2 d-flex align-items-center">
                        Total en Credito
                    </div>
                </div>
                <div className="totalIndicador">
                    { formatterSoles.format(credito.total) }
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="valortotalpasado">{ credito.label }</div>
                        <div className="valortotalpasado2">
                            { formatterSoles.format(credito.totalAyer) }
                        </div>
                    </div>
                    <div className="col align-items-center">
                        <div className="row">
                            <div id="idPorcentajeCred" 
                            className="fw-bolder fs-4 d-flex justify-content-end col">
                                { credito.porcentaje }
                            </div>
                            <div className="col-auto rounded d-flex align-items-center"
                            id='iconPorcCred'>
                                <FontAwesomeIcon icon={ credito.icon } 
                                color='white'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
