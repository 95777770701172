import React, { useEffect, useState } from 'react'
import { getArticuloEdit } from '../helpers/getArticuloEdit';
import { getTop5Grupo } from '../helpers/getTop5Grupo';
import { SelectFecha } from './resumen/SelectFecha';

export const Resumen = () => {

  const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

  const [tiempo, setTiempo] = useState(1);
  const [area, setArea] = useState('');
  const [tienda, setTienda] = useState('');
  const [optiontop5, setOptiontop5] = useState({
    serie : [],
    option : []
  });
  const [articuloedit, setArticuloedit] = useState({
    serie1 : [],
    option1 : []
  });

  function sum(total, curr) {
    return total + curr;
  }

  useEffect(() => {
    getTop5Grupo(1, "", "").then(e => {
      e.top10.option.push('Total');
      e.top10.serie.push(e.top10.serie.reduce(sum, 0));
      setOptiontop5({
        serie: e.top10.serie,
        option: e.top10.option
      })
    });
    getArticuloEdit(1, "", "").then(e => {
      // e.top10.option.push('Total');
      // e.top10.serie.push(e.top10.serie.reduce(sum, 0));
      setArticuloedit({
        serie1: e.top10.serie,
        option1: e.top10.option
      })
    });
  }, [])
  let counti = 0
  let counti1 = 0
  return (
    <div className='px-4'>
      <div className='row'>
        <div className='col'>
          {/* <SelectTienda setTienda={setTienda} tiempo={tiempo} area={area} /> */}
        </div>
        <div className='col'>
          {/* <SelectArea setArea={setArea} tienda={tienda} tiempo={tiempo} setTartcantidad= 
          {setTartcantidad} setTarttotal={setTarttotal}/> */}
        </div>
      </div>
      <div className='row justify-content-between pb-2'>
        <div className='col'>
          <div className='p-2' id='estadisticalbl'>
            Estadisticas
          </div>
        </div>
        <div className='col'>
          <div className='d-flex flex-row-reverse text-center'>
            <SelectFecha setTiempo={setTiempo} tienda={tienda} area={area} 
              setOptiontop5={setOptiontop5} setArticuloedit={setArticuloedit} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {/* <div className='card'> */}
            <div className='Container text-center ps-3'>
            {/* <div className='row'> */}
              {
                optiontop5.serie.map(
                  (fruit) => 
                  {
                    if (counti == 5) {
                      return <div className='row'>
                      <div className='col-8 border bg-warning'>{optiontop5.option[counti++]}</div>
                      <div className='col-3 border fw-semibold'>{ formatterSoles.format(fruit) }</div>
                    </div> 
                    }else{
                      return <div className='row'>
                        <div className='col-8 border'>{optiontop5.option[counti++]}</div>
                        <div className='col-3 border'>{ formatterSoles.format(fruit) }</div>
                      </div>
                    }
                  }
                )
              }
            {/* </div> */}
          </div>
        </div>
        <div className='col'>
            <div className='Container text-center ps-3'>
              {
                articuloedit.serie1.map(
                  (fruit) => 
                  {
                    // if (counti == 5) {
                    //   return <div className='row'>
                    //   <div className='col-8 border bg-warning'>{articuloedit.option[counti++]}</div>
                    //   <div className='col-3 border fw-semibold'>{ fruit }</div>
                    // </div> 
                    // }else{
                      return <div className='row'>
                        <div className='col-8 border'>{articuloedit.option1[counti1++]}</div>
                        <div className='col-3 border'>{ fruit }</div>
                      </div>
                    // }
                  }
                )
              }
          </div>
        </div>
      </div>
      {/* <div className='row'>
        <div className='col'>
            <div className='Container text-center ps-3'>
              {
                articuloedit.serie.map(
                  (fruit) => 
                  {
                    // if (counti == 5) {
                    //   return <div className='row'>
                    //   <div className='col-8 border bg-warning'>{articuloedit.option[counti++]}</div>
                    //   <div className='col-3 border fw-semibold'>{ fruit }</div>
                    // </div> 
                    // }else{
                      return <div className='row'>
                        <div className='col-8 border'>{articuloedit.option[counti++]}</div>
                        <div className='col-3 border'>{ fruit }</div>
                      </div>
                    // }
                  }
                )
              }
          </div>
        </div>
      </div> */}
    </div>
  )
}
