import React, { useRef } from 'react'
import { useState } from 'react';
import { DateRange, DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom'
import "../css/login.css"
import { getUsuario } from '../helpers/getUsuario';
import { useAuth } from '../auth';
export const Login = () => {

  const ref = useRef(null);
  const navigate = useNavigate();

  const [first, setFirst] = useState([
    {
      startDate : new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ])
  const auth = useAuth();
  const onLogin = (e) => {
    e.preventDefault();
    const pass = ref.current;

    getUsuario(pass.value).then(e => {
      if (e.cdusuario != undefined) {
        auth.login({
          cdusuario: e.cdusuario,
          dsusuario: e.dsusuario,
          pass : "",
        })
        navigate('/', {
          replace: true
        });  
      } else {
        alert("Contraseña no valida");
      }
    })
  }
  
  return (
    <div className='prueba'>  
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Ingresar</h3>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              ref={ref}
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type='button' className="btn btn-primary" onClick= {onLogin}>
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
        {/* <a tabindex="0" 
          class="btn btn-lg btn-danger" 
          role="button" 
          data-bs-toggle="popover" 
          data-bs-trigger="focus" 
          title="Dismissible popover" 
          data-bs-content="And here's some amazing content. It's very engaging. Right?">
            Dismissible popover
        </a> */}
        {/* <div className="d-flex justify-content-center">
          <DateRange 
            editableDateInputs={true}
            onChange={item => setFirst([item.selection])}          
            moveRangeOnFirstSelection={false}
            ranges={first}
            months={2}
            // direction="horizontal"
          />
        </div> */}
        {/* <DateRangePicker
          onChange={item => setFirst([item.selection])}
          // showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          // months={2}
          ranges={first}
          direction="horizontal"
          editableDateInputs={true}
        /> */}
      </form>
    </div>
    </div>
  )
}
