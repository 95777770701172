export const setUsuario = async (parametro, pass) => {
    let url = '';
    url = 
    // `https://apiflavorcup.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apigranja.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apidashdanie.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apidashdurabilis.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
        // `https://apidashboard.g11sac.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apidashpanda.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://localhost:7109/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apidashdanie.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    // `https://apichozanautica.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
        `https://apicordonylarosa.zigmaperu.com/api/usuario/updateuser/${ encodeURI(parametro)}/${ encodeURI(pass) }`
    const resp = await fetch(url, {method: 'POST', mode:'cors', headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
    }});
    const factura = await resp.json();
    return factura;
}


