import React from 'react'
import Chart from "react-apexcharts";

export const ClienteTotal = ({ tclitotal }) => {
  return (
    <>
    <div className='pt-3 ps-3'>Clientes con mayor consumo</div>
      <div id='chart' className='w-auto'>
        <Chart
          options={tclitotal.option}
          series={tclitotal.serie}
          type="donut"
          width="500"
          // height="300"
        />
      </div>
    </>
  )
}
