import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from './context'

export const RequireAuth = ({ children }) => {
    const auth = useAuth();


    // if (!auth.user.cdusuario) {
    //     return <Navigate to='/login' />
    // // }
    // console.log(localStorage.getItem('authed'));
    const usuario = JSON.parse(localStorage.getItem('authed'));
    // console.log(usuario.cdusuario);
    if (usuario != null) {
      if (localStorage.getItem('authed') == null || usuario.cdusuario == 0) {
        return <Navigate to='/login' />
      } 
    } else {
      return <Navigate to='/login' />
    }

  return children
}
