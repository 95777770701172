export const getFacturaXfecha = async(fecha1, fecha2, tienda, area, cdprecio) => {
    const totales = [];
    let url = '';
    if (tienda == "" || tienda == '00') tienda = "00";
    if (area == "" || area == '00') area = "00";
    if (cdprecio == "" || cdprecio == '00') cdprecio = "00";

    let ctmr = encodeURI(fecha1);
    let ptmr = encodeURI(fecha2);
    // console.log(ctmr);
    console.log(fecha1);
    console.log(fecha2);
    url = 
    `https://apiflavorcup.zpinfosac.com/api/facturadashboards/fechas/
    ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apigranja.zigmaperu.com/api/factura/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apidashdanie.zigmaperu.com/api/factura/fechas/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://apidashdurabilis.zigmaperu.com/api/factura/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apidashboard.g11sac.com/api/factura/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://localhost:7109/api/factura/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://apichozanautica.zigmaperu.com/api/factura/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
//    `https://apicordonylarosa.zigmaperu.com/api/factura/fechas/
//     ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    // `https://localhost:7249/api/facturadashboards/fechas/
    // ${ encodeURI(fecha1)}/${ encodeURI(fecha2)}/${ encodeURI(tienda)}/${ encodeURI(area)}/${ encodeURI(cdprecio)}`
    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}
