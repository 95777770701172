import React from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import { RequireAuth } from '../auth/RequireAuth'
import { Login } from '../pages/Login'
import { EstadistRoutes } from '../routes/EstadistRoutes'

export const AppRouter = () => {
  return (
    <>
    {/* <Router> */}
      <Routes>
          <Route path='login' element={<Login />} />
          <Route path='/*' element={<RequireAuth><EstadistRoutes /></RequireAuth>} />
      </Routes>
    {/* </Router> */}
    </>
  )
}
