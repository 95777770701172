import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TotalConsumo = ({ consumo }) => {

    const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = consumo.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentajeCons');
    var elem1 = document.getElementById('iconPorcCons');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }
  return (
    <div className="col">
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-auto">
                        <FontAwesomeIcon icon={ 'fa-solid fa-sack-dollar' } 
                        size='2x' color='green'/>
                    </div>
                    <div className="col valortotalpasado2 d-flex align-items-center">
                        Total en Consumo
                    </div>
                </div>
                <div className="totalIndicador">
                    { formatterSoles.format(consumo.total) }
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="valortotalpasado">{ consumo.label }</div>
                        <div className="valortotalpasado2">
                            { formatterSoles.format(consumo.totalAyer) }
                        </div>
                    </div>
                    <div className="col align-items-center">
                        <div className="row">
                            <div id="idPorcentajeCons" 
                            className="fw-bolder fs-4 d-flex justify-content-end col">
                                { consumo.porcentaje }
                            </div>
                            <div className="col-auto rounded d-flex align-items-center"
                            id='iconPorcCons'>
                                <FontAwesomeIcon icon={ consumo.icon } 
                                color='white'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
