export const getFacturaChart = async(parametro, tienda, area, cdprecio) => {
    const totales = [];
    let url = '';
    if (tienda == "" || tienda == '00') tienda = "00";
    if (area == "" || area == '00') area = "00";
    if (cdprecio == "" || cdprecio == '00') cdprecio = "00";
    url = 
    `https://apiflavorcup.zpinfosac.com/api/facturadashboards/chart/
    ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`
    // `https://localhost:7249/api/facturadashboards/chart/
    // ${ encodeURI(parametro)}/${ encodeURI(tienda)}/${ encodeURI(area)}`

    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}