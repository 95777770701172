import { useEffect, useState } from "react";
import { SelectArea } from "./top10/SelectArea";
import { getTop10 } from "../helpers/getTop10";
import { getTop10Total } from "../helpers/getTop10Total";
import { ArtCantidad } from "./top10/ArtCantidad";
import { ArtTotal } from "./top10/ArtTotal";
import { SelectFecha } from "./top10/SelectFecha";
import { SelectTienda } from "./top10/SelectTienda";
import { ClienteTotal } from "./top10/ClienteTotal";
import { getTopCliente } from "../helpers/getTopCliente";

export const Top10 = () => {

  const [tiempo, setTiempo] = useState(1);
  const [area, setArea] = useState('');
  const [tienda, setTienda] = useState('');
  const [icons, setIcons] = useState('fa-solid fa-arrow-trend-up');
  const [serie1, setSerie1] = useState([]);
  const [serie2, setSerie2] = useState([]);
  const [efectivo, setEfectivo] = useState(
    {label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'}
  );
  const [tarjeta, setTarjeta] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [anulacion, setAnulacion] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [ticket, setTicket] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [transac, setTransac] = useState({
    label: 'Hoy', total: 0, totalAyer: 0, porcentaje: '0%', icono: 'fa-solid fa-arrow-trend-up'
  });
  const [options, setoptions] = useState({
    chart: {
      height: 280,
      type: "area"
    },
    xaxis: {
      categories: []
    },
    fill: {
      type: ["gradient", "gradient"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: [0.1, 0.7],
        opacityTo: [0.7, 0.9]
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [0.5, 3]
    }
  });
  const [total, setTotal] = useState({
    total: "19,224.34",
    totalPasado: "18,500.14",
    label1: 'Total de ventas de hoy',
    label2: 'ayer',
    porcentaje: '+ 5 %',
    resta: 'Aumento: S/ 724.20'
  });
  const [tartcantidad, setTartcantidad] = useState({
    serie : [],
    option : {
      chart: {
        type: 'donut',
      },
      labels : [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 310
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
    }
  });
  const [tarttotal, setTarttotal] = useState({
    serie : [],
    option : {
      chart: {
        type: 'donut',
      },
      labels : [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 310
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
    }
  })
  const [tclitotal, setTclitotal] = useState({
    serie : [],
    option : {
      chart: {
        type: 'donut',
      },
      labels : [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 310
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
    }
  })


  useEffect(() => {
    getTop10(1, "", "").then(e => {
      setTartcantidad({
        serie: e.top10.serie,
        option: { labels: e.top10.option}
      })
    });
    getTop10Total(1, "", "").then(e => {
      setTarttotal({
        serie: e.top10.serie,
        option: { labels: e.top10.option }
      })
    });
    getTopCliente(1, "", "").then(e => {
      setTclitotal({
        serie: e.top10.serie,
        option: { labels: e.top10.option}
      })
    });
  }, [])
  

  return (

    <div className="px-4">
      <div className='row'>
        <div className='col'>
          {/* <SelectTienda setTienda={setTienda} tiempo={tiempo} area={area} setTartcantidad=
          {setTartcantidad} setTarttotal={setTarttotal} /> */}
        </div>
        <div className='col'>
          {/* <SelectArea setArea={setArea} tienda={tienda} tiempo={tiempo} setTartcantidad= 
          {setTartcantidad} setTarttotal={setTarttotal}/> */}
        </div>
      </div>
      <div className='row justify-content-between pb-2'>
        <div className='col'>
          <div className='p-2' id='estadisticalbl'>
            Estadisticas
          </div>
        </div>
        <div className='col'>
          <div className='d-flex flex-row-reverse text-center'>
            <SelectFecha setTiempo={setTiempo} tienda={tienda} area={area} setTartcantidad=
            {setTartcantidad} setTarttotal={setTarttotal} setTclitotal={setTclitotal}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='card'>
            {/* <div className='row g-0'>
              <div className='col-md-5'>
                <div className='card-body pt-4'> */}
                  <ArtCantidad tartcantidad={tartcantidad} />
                {/* </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col">
          <div className='card'>
            {/* <div className='row g-0'> */}
              {/* <div className='col-md-5'> */}
                {/* <div className='card-body pt-4'> */}
                  <ArtTotal tarttotal={tarttotal} />
                {/* </div> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        <div className="col">
          <div className='card'>
            {/* <div className='row g-0'>
              <div className='col-md-5'>
                <div className='card-body pt-4'> */}
                  <ClienteTotal tclitotal={tclitotal} />
                {/* </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
