import { render } from '@testing-library/react';
import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

export const ChartComparativo = ({ options, serie1, serie2 }) => {

    // let series = [{
    //   name: "Series 1",
    //   // data: [45, 52, 38, 45, 19, 23, 2]
    //   data: [2045.972, 1952.222, 1338.637, 1745, 2119, 2145, 1552]
    // },{
    //   name: 'series2',
    //   // data: [11, 32, 45, 32, 34, 52, 41]
    //   data: [2111, 2932, 2345, 2732, 2134.999, 2649, 2850]
    // }];

  // }
  // console.log(options);
  // console.log(serie1);
  // console.log(serie2);
  
  let series = [{
      name: "Anterior",
      data: serie1
    },{
      name: 'Actual',
      data: serie2
    }]
    return (
      <div id='chart' className='col p-4'>
      <Chart id = "apexchart"
          options={options}
          series={series}
          type="area"
          width="100%"
          height="300"
        />
      {/* <chart /> */}
    </div>
    )  
}
