import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TotalTarjeta = ({ tarjeta }) => {

    const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

    var porcentajeVal = tarjeta.porcentaje.substring(0, 1);
    var elem = document.getElementById('idPorcentajeTarjeta');
    var elem1 = document.getElementById('iconPorcTarjeta');
    if (elem != null) {
        if (porcentajeVal == '-') {
            elem.style.color = '#ff4d4d';
            elem1.style.backgroundColor = '#ff4d4d';
        } else {
            elem.style.color = '#58C347';
            elem1.style.backgroundColor = '#58C347';
        }
    }

    return (
        <>
        
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={ 'fa-solid fa-credit-card' } 
                            size='2x' color='#40A5E2'/>
                        </div>
                        <div className="col valortotalpasado2 d-flex align-items-center">
                            Total de ventas en tarjeta debito/credito
                        </div>
                    </div>
                    <div className="totalIndicador">
                        { formatterSoles.format(tarjeta.total) }
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="valortotalpasado">{ tarjeta.label }</div>
                            <div className="valortotalpasado2">
                                { formatterSoles.format(tarjeta.totalAyer) }
                            </div>
                        </div>
                        <div className="col align-items-center">
                            <div className="row">
                                <div id="idPorcentajeTarjeta" 
                                className="fw-bolder fs-4 d-flex justify-content-end col">
                                    { tarjeta.porcentaje }
                                </div>
                                <div className="col-auto rounded d-flex align-items-center"
                                id='iconPorcTarjeta'>
                                    <FontAwesomeIcon icon={ tarjeta.icon } 
                                    color='white'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}