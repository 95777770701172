export const getUsuario = async (parametro) => {
    let url = '';
    url = 
    // `https://apiflavorcup.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apigranja.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apidashdanie.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apidashdurabilis.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apidashboard.g11sac.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apidashpanda.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apidashdanie.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    // `https://apichozanautica.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
        `https://apicordonylarosa.zigmaperu.com/api/usuario/${ encodeURI(parametro)}`
    const resp = await fetch(url, {mode:'cors'});
    const factura = await resp.json();
    return factura;
}



