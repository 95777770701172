import React from 'react'
import { Navbara } from '../ui'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Top10 } from '../pages/Top10'
import { IndexApp } from '../IndexApp'
import { Profile } from '../pages/profile/Profile'
import { Grupo } from '../pages/Grupo'
import { Resumen } from '../pages/Resumen'

export const EstadistRoutes = () => {
  return (
    <>
        <Navbara />
          <Routes>
            <Route path='estadisticas' element={<IndexApp />} />
            <Route path='top10' element={<Top10 />} />
            <Route path='grupo' element={<Grupo />} />
            <Route path='resumen' element={<Resumen />} />
            <Route path='/' element={<Navigate to="/estadisticas" />} />
            <Route path='profile' element={<Profile />} />
          </Routes>
    </>
  )
}
