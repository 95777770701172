import React, { useEffect, useState } from 'react'
import { SelectTienda } from '../components/SelectTienda';
import { getTop5Grupo } from '../helpers/getTop5Grupo';
import { Grupotop5 } from './categoria/Grupotop5';
import { GrupoTotal } from './categoria/GrupoTotal';
import { SelectFecha } from './categoria/SelectFecha';

export const Grupo = () => {

    const [tiempo, setTiempo] = useState(1);
    const [area, setArea] = useState('');
    const [tienda, setTienda] = useState('');
    const [series, setSeries] = useState([{
      name: 'Total',
      data: [1104137, 241291, 215293, 125710, 87298]
    }]);
    const [series1, setSeries1] = useState([{
      name: 'Total',
      data: [3044, 1055, 941, 967]
    }]);
    
    const [options, setOptions] = useState({
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },
      
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        categories: ['Alimentos Carta', 'Bebidas Carta', 'Promociones', 
        'Consecionarios', 'Otros'
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Totales',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      }
    });

    const [optiontop5, setOptiontop5] = useState({
      serie : [],
      option : []
    });
    useEffect(() => {
      getTop5Grupo(1, "", "").then(e => {
        setOptiontop5({
          serie: e.top10.serie,
          option: e.top10.option
        })
      });
    }, [])
    
  return (
    <div className="px-4">
      <div className='row'>
        <div className='col'>
          {/* <SelectTienda setTienda={setTienda} tiempo={tiempo} area={area} /> */}
        </div>
        <div className='col'>
          {/* <SelectArea setArea={setArea} tienda={tienda} tiempo={tiempo} setTartcantidad= 
          {setTartcantidad} setTarttotal={setTarttotal}/> */}
        </div>
      </div>
      <div className='row justify-content-between pb-2'>
        <div className='col'>
          <div className='p-2' id='estadisticalbl'>
            Estadisticas
          </div>
        </div>
        <div className='col'>
          <div className='d-flex flex-row-reverse text-center'>
            <SelectFecha setTiempo={setTiempo} tienda={tienda} area={area} 
              setOptiontop5={setOptiontop5} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='card'>
            <div className='row g-0'>
              <div className='col-md-5'>
                <div className='card-body pt-4'>
                  <Grupotop5 optiontop5={optiontop5} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col">
          <div className='card'>
            <div className='row g-0'>
              <div className='col-md-5'>
                <div className='card-body pt-4'>
                  <Grupotop5 options={options1} series={series1} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
