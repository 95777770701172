import React, { useState } from 'react'
import { getFacturas } from '../helpers/getFacturas';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRange } from 'react-date-range';
import { getFacturaXfecha } from '../helpers/getFacturaXfecha';
import { getFacturaChart } from '../helpers/getFacturaChart';
import { getFacturaChartXfecha } from '../helpers/getFacturaChartXfecha';

export const Hoysemanames = ({ setoptions, setTotal, setSerie1, setSerie2, 
  area, setIcons, setEfectivo, setTarjeta, setAnulacion, setTicket, setTransac,
setTiempo, tienda, setCredito, cdprecio, setConsumo}) => {

  const formatterSoles = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' });

  const [daterango, setDaterango] = useState([
    {    
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    }
  ]);
    const crearOption = (valor) => {
      let option1;
      let lblTotal = '';
      let lblTotalPasado = '';
      switch (valor) {
        case 1:
          lblTotal = 'Total de ventas de hoy';
          lblTotalPasado = 'Ayer';
          setTiempo(1);
          break;
        case 2:
          lblTotal = 'Total de ventas de la semana';
          lblTotalPasado = 'Semana pasado';
          setTiempo(2);
          break;
        case 3:
          lblTotal = 'Total de ventas del mes';
          lblTotalPasado = 'Mes pasado';
          setTiempo(3);
          break;
        case 0:
          lblTotal = '';
          lblTotalPasado = '';
          setTiempo(0);
          break;
        default:
          break;
      }
      if (valor == 0 ) {
        var fecha1 = convert(daterango[0].startDate);
        var fecha2 = convert(daterango[0].endDate);

        if (daterango[0].endDate == null) {
          fecha2 = new Date().toLocaleDateString("en-US").replaceAll('/','-');
        }
        
        getFacturaXfecha(fecha1, fecha2, tienda, area, cdprecio).then(e => {
          console.log(e);
          let total = e.indicadores[0].total;
          let totalPasado = e.indicadores[0].totalAnt;
          // let total = e.dataTotales.reduce((a, b) => {
          //   return a + b;
          // }, 0);
          // let totalPasado = e.dataTotalesAnt.reduce((a, b) => {
          //   return a + b;
          // }, 0);      
          let porcentaje = "";
  
          if (total == 0 && totalPasado > 0) {
            porcentaje = "-100%";
          } else if(totalPasado == 0 && total >0){
            porcentaje = "+100%";
          } else if(total == 0 && totalPasado == 0){
            porcentaje = "+0%";
          } else if(total > 0 && totalPasado > 0){
            porcentaje = (100 * (total - totalPasado) / totalPasado).toFixed(2);
            porcentaje = porcentaje.toString().substring(0, 1) == '-' ? porcentaje.toString() + '%' :
            '+' + porcentaje.toString() + '%';
          }
          let lblAumento = '';
          if (total - totalPasado < 0) {
            lblAumento = 'Disminucion';
            setIcons('fa-solid fa-arrow-trend-down');
          } else {
            lblAumento = 'Aumento'
            setIcons('fa-solid fa-arrow-trend-up');
          }
          // option1 = {
          //   chart: {
          //     height: 280,
          //     type: "area"
          //   },
          //   xaxis: {
          //     type: 'datetime',
          //     categories: e.categoria
          //   },
          //   fill: {
          //     type: ["gradient", "gradient"],
          //     gradient: { 
          //       shadeIntensity: 1,
          //       opacityFrom: [ 0.1, 0.7],
          //       opacityTo: [ 0.7, 0.9]
          //     } 
          //   },
          //   dataLabels: {
          //     enabled: false
          //   },
          //   stroke:
          //   {
          //       width: [0.5, 3]
          //   }
          // };
          // setoptions(option1);
          // setSerie1(e.dataTotalesAnt);
          // setSerie2(e.dataTotales);
          setTotal({
            total: formatterSoles.format(total + e.indicadores[0].totInterno),
            totalPasado: formatterSoles.format(totalPasado + e.indicadores[0].totInternoAnt),
            label1: lblTotal,
            label2: lblTotalPasado,
            porcentaje: porcentaje,
            resta: lblAumento + ' : ' + formatterSoles.format(total - totalPasado)
          });
          // let porcEfectivo = 
          // (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
          // e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
          // let porcTarjeta = 
          // (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
          // e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
  
  
          let porcEfectivo = "0%";
          let porcTarjeta = "0%";
          let porcAnulacion = "0%";
          let porcTicket = "0%";
          let porcTransa = "0%";
          let porcCredito = "0%";
          let porcConsumo = "0%";

          if (e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt > 0) {
            porcEfectivo = "-100%";
          } else if(e.indicadores[0].totEfectivoAnt == 0 && e.indicadores[0].totEfectivo > 0){
            porcEfectivo = "100%";
          } else if(e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt == 0){
            porcEfectivo = "0%";
          } else if(e.indicadores[0].totEfectivo > 0 && e.indicadores[0].totEfectivoAnt > 0){
            porcEfectivo = 
            (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
            e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
          }
    
          if (e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt > 0) {
            porcTarjeta = "-100%";
          } else if(e.indicadores[0].totTarjetaAnt == 0 && e.indicadores[0].totTarjeta > 0){
            porcTarjeta = "100%";
          } else if(e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt == 0){
            porcTarjeta = "0%";
          } else if(e.indicadores[0].totTarjeta > 0 && e.indicadores[0].totTarjetaAnt > 0){
            porcTarjeta = 
            (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
            e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
          }
    
          if (e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt > 0) {
            porcAnulacion = "-100%";
          } else if(e.indicadores[0].cantAnulacionAnt == 0 && e.indicadores[0].cantAnulacion >0){
            porcAnulacion = "100%";
          } else if(e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt == 0){
            porcAnulacion = "0%";
          } else if(e.indicadores[0].cantAnulacion > 0 && e.indicadores[0].cantAnulacionAnt > 0){
            porcAnulacion = 
            (100 * (e.indicadores[0].cantAnulacion - e.indicadores[0].cantAnulacionAnt) / 
            e.indicadores[0].cantAnulacionAnt).toFixed(2) + '%';
          }
          
          if (e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt > 0) {
            porcTicket = "-100%";
          } else if(e.indicadores[0].tiketPromAnt == 0 && e.indicadores[0].tiketProm >0){
            porcTicket = "100%";
          } else if(e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt == 0){
            porcTicket = "0%";
          } else if(e.indicadores[0].tiketProm > 0 && e.indicadores[0].tiketPromAnt > 0){
            porcTicket = 
            (100 * (e.indicadores[0].tiketProm - e.indicadores[0].tiketPromAnt) / 
            e.indicadores[0].tiketPromAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt > 0) {
            porcTransa = "-100%";
          } else if(e.indicadores[0].cantTransacAnt == 0 && e.indicadores[0].cantTransac >0){
            porcTransa = "100%";
          } else if(e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt == 0){
            porcTransa = "0%";
          } else if(e.indicadores[0].cantTransac > 0 && e.indicadores[0].cantTransacAnt > 0){
            porcTransa = 
            (100 * (e.indicadores[0].cantTransac - e.indicadores[0].cantTransacAnt) / 
            e.indicadores[0].cantTransacAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].cantCredito == 0 && e.indicadores[0].cantCreditoAnt > 0) {
            porcCredito = "-100%";
          } else if(e.indicadores[0].cantCreditoAnt == 0 && e.indicadores[0].cantCredito >0){
            porcCredito = "100%";
          } else if(e.indicadores[0].cantCredito == 0 && e.indicadores[0].cantCreditoAnt == 0){
            porcCredito = "0%";
          } else if(e.indicadores[0].cantCredito > 0 && e.indicadores[0].cantCreditoAnt > 0){
            porcCredito = 
            (100 * (e.indicadores[0].cantCredito - e.indicadores[0].cantCreditoAnt) / 
            e.indicadores[0].cantCreditoAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].totInterno == 0 && e.indicadores[0].totInternoAnt > 0) {
            porcConsumo = "-100%";
          } else if(e.indicadores[0].totInternoAnt == 0 && e.indicadores[0].totInterno >0){
            porcConsumo = "100%";
          } else if(e.indicadores[0].totInterno == 0 && e.indicadores[0].totInternoAnt == 0){
            porcConsumo = "0%";
          } else if(e.indicadores[0].totInterno > 0 && e.indicadores[0].totInternoAnt > 0){
            porcConsumo = 
            (100 * (e.indicadores[0].totInterno - e.indicadores[0].totInternoAnt) / 
            e.indicadores[0].totInternoAnt).toFixed(2) + '%';
          }

          setEfectivo({
            label: lblTotalPasado, 
            total: e.indicadores[0].totEfectivo, 
            totalAyer: e.indicadores[0].totEfectivoAnt, 
            porcentaje: porcTarjeta,
            icon: porcEfectivo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setTarjeta({
            label: lblTotalPasado, 
            total: e.indicadores[0].totTarjeta, 
            totalAyer: e.indicadores[0].totTarjetaAnt, 
            porcentaje: porcEfectivo,
            icon: porcEfectivo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          })
          setAnulacion({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantAnulacion, 
            totalAyer: e.indicadores[0].cantAnulacionAnt, 
            porcentaje: porcAnulacion,
            icon: porcAnulacion.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          })
          setTicket({
            label: lblTotalPasado, 
            total: e.indicadores[0].tiketProm, 
            totalAyer: e.indicadores[0].tiketPromAnt, 
            porcentaje: porcTicket,
            icon: porcTicket.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setTransac({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantTransac, 
            totalAyer: e.indicadores[0].cantTransacAnt, 
            porcentaje: porcTransa,
            icon: porcTransa.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setCredito({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantCredito, 
            totalAyer: e.indicadores[0].cantCreditoAnt, 
            porcentaje: porcCredito,
            icon: porcCredito.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setConsumo({
            label: lblTotalPasado, 
            total: e.indicadores[0].totInterno, 
            totalAyer: e.indicadores[0].totInternoAnt, 
            porcentaje: porcConsumo,
            icon: porcConsumo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
        })
      } else {
        getFacturas(valor, tienda, area, cdprecio).then(e => {
          let total = e.indicadores[0].total;
          let totalPasado = e.indicadores[0].totalAnt;
          // let total = e.dataTotales.reduce((a, b) => {
          //   return a + b;
          // }, 0);
          // let totalPasado = e.dataTotalesAnt.reduce((a, b) => {
          //   return a + b;
          // }, 0);      
          let porcentaje = "";
  
          if (total == 0 && totalPasado > 0) {
            porcentaje = "-100%";
          } else if(totalPasado == 0 && total >0){
            porcentaje = "+100%";
          } else if(total == 0 && totalPasado == 0){
            porcentaje = "+0%";
          } else if(total > 0 && totalPasado > 0){
            porcentaje = (100 * (total - totalPasado) / totalPasado).toFixed(2);
            porcentaje = porcentaje.toString().substring(0, 1) == '-' ? porcentaje.toString() + '%' :
            '+' + porcentaje.toString() + '%';
          }
          let lblAumento = '';
          if (total - totalPasado < 0) {
            lblAumento = 'Disminucion';
            setIcons('fa-solid fa-arrow-trend-down');
          } else {
            lblAumento = 'Aumento'
            setIcons('fa-solid fa-arrow-trend-up');
          }
          // option1 = {
          //   chart: {
          //     height: 280,
          //     type: "area"
          //   },
          //   xaxis: {
          //     type: 'datetime',
          //     categories: e.categoria
          //   },
          //   fill: {
          //     type: ["gradient", "gradient"],
          //     gradient: { 
          //       shadeIntensity: 1,
          //       opacityFrom: [ 0.1, 0.7],
          //       opacityTo: [ 0.7, 0.9]
          //     } 
          //   },
          //   dataLabels: {
          //     enabled: false
          //   },
          //   stroke:
          //   {
          //       width: [0.5, 3]
          //   }
          // };
          // setoptions(option1);
          // setSerie1(e.dataTotalesAnt);
          // setSerie2(e.dataTotales);
          setTotal({
            total: formatterSoles.format(total + e.indicadores[0].totInterno),
            totalPasado: formatterSoles.format(totalPasado + e.indicadores[0].totInternoAnt),
            label1: lblTotal,
            label2: lblTotalPasado,
            porcentaje: porcentaje,
            resta: lblAumento + ' : ' + formatterSoles.format(total - totalPasado)
          });
          // let porcEfectivo = 
          // (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
          // e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
          // let porcTarjeta = 
          // (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
          // e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
  
  
          let porcEfectivo = "0%";
          let porcTarjeta = "0%";
          let porcAnulacion = "0%";
          let porcTicket = "0%";
          let porcTransa = "0%";
          let porcCredito = "0%";
        let porcConsumo = "0%";

          if (e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt > 0) {
            porcEfectivo = "-100%";
          } else if(e.indicadores[0].totEfectivoAnt == 0 && e.indicadores[0].totEfectivo > 0){
            porcEfectivo = "100%";
          } else if(e.indicadores[0].totEfectivo == 0 && e.indicadores[0].totEfectivoAnt == 0){
            porcEfectivo = "0%";
          } else if(e.indicadores[0].totEfectivo > 0 && e.indicadores[0].totEfectivoAnt > 0){
            porcEfectivo = 
            (100 * (e.indicadores[0].totEfectivo - e.indicadores[0].totEfectivoAnt) / 
            e.indicadores[0].totEfectivoAnt).toFixed(2) + '%';
          }
    
          if (e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt > 0) {
            porcTarjeta = "-100%";
          } else if(e.indicadores[0].totTarjetaAnt == 0 && e.indicadores[0].totTarjeta > 0){
            porcTarjeta = "100%";
          } else if(e.indicadores[0].totTarjeta == 0 && e.indicadores[0].totTarjetaAnt == 0){
            porcTarjeta = "0%";
          } else if(e.indicadores[0].totTarjeta > 0 && e.indicadores[0].totTarjetaAnt > 0){
            porcTarjeta = 
            (100 * (e.indicadores[0].totTarjeta - e.indicadores[0].totTarjetaAnt) / 
            e.indicadores[0].totTarjetaAnt).toFixed(2) + '%';
          }
    
          if (e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt > 0) {
            porcAnulacion = "-100%";
          } else if(e.indicadores[0].cantAnulacionAnt == 0 && e.indicadores[0].cantAnulacion >0){
            porcAnulacion = "100%";
          } else if(e.indicadores[0].cantAnulacion == 0 && e.indicadores[0].cantAnulacionAnt == 0){
            porcAnulacion = "0%";
          } else if(e.indicadores[0].cantAnulacion > 0 && e.indicadores[0].cantAnulacionAnt > 0){
            porcAnulacion = 
            (100 * (e.indicadores[0].cantAnulacion - e.indicadores[0].cantAnulacionAnt) / 
            e.indicadores[0].cantAnulacionAnt).toFixed(2) + '%';
          }
          
          if (e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt > 0) {
            porcTicket = "-100%";
          } else if(e.indicadores[0].tiketPromAnt == 0 && e.indicadores[0].tiketProm >0){
            porcTicket = "100%";
          } else if(e.indicadores[0].tiketProm == 0 && e.indicadores[0].tiketPromAnt == 0){
            porcTicket = "0%";
          } else if(e.indicadores[0].tiketProm > 0 && e.indicadores[0].tiketPromAnt > 0){
            porcTicket = 
            (100 * (e.indicadores[0].tiketProm - e.indicadores[0].tiketPromAnt) / 
            e.indicadores[0].tiketPromAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt > 0) {
            porcTransa = "-100%";
          } else if(e.indicadores[0].cantTransacAnt == 0 && e.indicadores[0].cantTransac >0){
            porcTransa = "100%";
          } else if(e.indicadores[0].cantTransac == 0 && e.indicadores[0].cantTransacAnt == 0){
            porcTransa = "0%";
          } else if(e.indicadores[0].cantTransac > 0 && e.indicadores[0].cantTransacAnt > 0){
            porcTransa = 
            (100 * (e.indicadores[0].cantTransac - e.indicadores[0].cantTransacAnt) / 
            e.indicadores[0].cantTransacAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].cantCredito == 0 && e.indicadores[0].cantCreditoAnt > 0) {
            porcCredito = "-100%";
          } else if(e.indicadores[0].cantCreditoAnt == 0 && e.indicadores[0].cantCredito >0){
            porcCredito = "100%";
          } else if(e.indicadores[0].cantCredito == 0 && e.indicadores[0].cantCreditoAnt == 0){
            porcCredito = "0%";
          } else if(e.indicadores[0].cantCredito > 0 && e.indicadores[0].cantCreditoAnt > 0){
            porcCredito = 
            (100 * (e.indicadores[0].cantCredito - e.indicadores[0].cantCreditoAnt) / 
            e.indicadores[0].cantCreditoAnt).toFixed(2) + '%';
          }
  
          if (e.indicadores[0].totInterno == 0 && e.indicadores[0].totInternoAnt > 0) {
            porcConsumo = "-100%";
          } else if(e.indicadores[0].totInternoAnt == 0 && e.indicadores[0].totInterno >0){
            porcConsumo = "100%";
          } else if(e.indicadores[0].totInterno == 0 && e.indicadores[0].totInternoAnt == 0){
            porcConsumo = "0%";
          } else if(e.indicadores[0].totInterno > 0 && e.indicadores[0].totInternoAnt > 0){
            porcConsumo = 
            (100 * (e.indicadores[0].totInterno - e.indicadores[0].totInternoAnt) / 
            e.indicadores[0].totInternoAnt).toFixed(2) + '%';
          }

          setEfectivo({
            label: lblTotalPasado, 
            total: e.indicadores[0].totEfectivo, 
            totalAyer: e.indicadores[0].totEfectivoAnt, 
            porcentaje: porcTarjeta,
            icon: porcEfectivo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setTarjeta({
            label: lblTotalPasado, 
            total: e.indicadores[0].totTarjeta, 
            totalAyer: e.indicadores[0].totTarjetaAnt, 
            porcentaje: porcEfectivo,
            icon: porcEfectivo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          })
          setAnulacion({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantAnulacion, 
            totalAyer: e.indicadores[0].cantAnulacionAnt, 
            porcentaje: porcAnulacion,
            icon: porcAnulacion.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          })
          setTicket({
            label: lblTotalPasado, 
            total: e.indicadores[0].tiketProm, 
            totalAyer: e.indicadores[0].tiketPromAnt, 
            porcentaje: porcTicket,
            icon: porcTicket.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setTransac({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantTransac, 
            totalAyer: e.indicadores[0].cantTransacAnt, 
            porcentaje: porcTransa,
            icon: porcTransa.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setCredito({
            label: lblTotalPasado, 
            total: e.indicadores[0].cantCredito, 
            totalAyer: e.indicadores[0].cantCreditoAnt, 
            porcentaje: porcCredito,
            icon: porcCredito.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
          setConsumo({
            label: lblTotalPasado, 
            total: e.indicadores[0].totInterno, 
            totalAyer: e.indicadores[0].totInternoAnt, 
            porcentaje: porcConsumo,
            icon: porcConsumo.substring(0, 1) == '-' ? 
            'fa-solid fa-arrow-trend-down fa-1x' : 
            'fa-solid fa-arrow-trend-up fa-1x'
          });
        })
      }
      if (valor == 0) {
        var fecha1 = convert(daterango[0].startDate);
        var fecha2 = convert(daterango[0].endDate);

        if (daterango[0].endDate == null) {
          fecha2 = new Date().toLocaleDateString("en-US").replaceAll('/','-');
        }
        getFacturaChartXfecha(fecha1, fecha2, tienda, area, cdprecio).then(e => {
          option1 = {
            chart: {
              height: 280,
              type: "area"
            },
            xaxis: {
              type: 'datetime',
              categories: e.categoria
            },
            fill: {
              type: ["gradient", "gradient"],
              gradient: { 
                shadeIntensity: 1,
                opacityFrom: [ 0.1, 0.7],
                opacityTo: [ 0.7, 0.9]
              } 
            },
            dataLabels: {
              enabled: false
            },
            stroke:
            {
                width: [0.5, 3]
            }
          };
          setoptions(option1);
          setSerie1(e.dataTotalesAnt);
          setSerie2(e.dataTotales);
        });
      } else {
        getFacturaChart(valor, "", "", '').then(e => {

          option1 = {
            chart: {
              height: 280,
              type: "area"
            },
            xaxis: {
              type: 'datetime',
              categories: e.categoria
            },
            fill: {
              type: ["gradient", "gradient"],
              gradient: { 
                shadeIntensity: 1,
                opacityFrom: [ 0.1, 0.7],
                opacityTo: [ 0.7, 0.9]
              } 
            },
            dataLabels: {
              enabled: false
            },
            stroke:
            {
                width: [0.5, 3]
            }
          };
          setoptions(option1);
          setSerie1(e.dataTotalesAnt);
          setSerie2(e.dataTotales);
        });
      }
    }

    const clickColor = (e) => {
      var elem = document.getElementById(e.target.id);
      setFecha({
        colordiv: 'light'
      })
      document.getElementById('divFecha').style.backgroundColor = 'transparent';
      document.getElementById('divFecha').style.color = 'grey';

      switch (e.target.id) {
        case 'divmes':
          elem.style.backgroundColor = '#2BC1EE';
          elem.style.color = 'white';

          document.getElementById('divhoy').style.backgroundColor = 'transparent';
          document.getElementById('divsemana').style.backgroundColor = 'transparent';

          document.getElementById('divhoy').style.color = 'grey';
          document.getElementById('divsemana').style.color = 'grey';

          crearOption(3);

          break;
        case 'divhoy':
          elem.style.backgroundColor = '#2BC1EE';
          elem.style.color = 'white';
          document.getElementById('divmes').style.backgroundColor = 'transparent';
          document.getElementById('divsemana').style.backgroundColor = 'transparent';

          document.getElementById('divmes').style.color = 'grey';
          document.getElementById('divsemana').style.color = 'grey';

          crearOption(1);

          break;
        case 'divsemana':
          elem.style.backgroundColor = '#2BC1EE';
          elem.style.color = 'white';

          document.getElementById('divhoy').style.backgroundColor = 'transparent';
          document.getElementById('divmes').style.backgroundColor = 'transparent';

          document.getElementById('divhoy').style.color = 'grey';
          document.getElementById('divmes').style.color = 'grey';

          crearOption(2);

          break;
        case '':
          // elem.style.backgroundColor = '#2BC1EE';
          // elem.style.color = 'white'; 
          setFecha({
            colordiv: 'info'
          })
          break;

          default:
          break;
      }
    }
    const clickColorFecha = (e) => {
      crearOption(0);
      setFecha({
        colordiv: 'info'
      });
      document.getElementById('divmes').style.backgroundColor = 'transparent';
      document.getElementById('divsemana').style.backgroundColor = 'transparent';
      document.getElementById('divhoy').style.backgroundColor = 'transparent';

      document.getElementById('divhoy').style.color = 'grey';
      document.getElementById('divmes').style.color = 'grey';
      document.getElementById('divsemana').style.color = 'grey';
    }
    const handleSelect = (ranges) => {
      console.log(ranges);
      // {
      //   selection: {
      //     startDate: [native Date Object],
      //     endDate: [native Date Object],
      //   }
      // }
    }
    function format (locale, currency, number) {
      return new Intl.NumberFormat(locale, { 
        style: 'currency', 
        currency, 
        currencyDisplay: "code" 
      })
      .format(number)
      .replace(currency, "")
      .trim();
    }
    const popover = (
      <div className='border'>
          <DateRange editableDateInputs={true} 
          onChange={item => setDaterango([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={daterango} />
      </div>
    );

  const [fecha, setFecha] = useState({
    colordiv: 'light'
  })

  function convert(str) {
    var date = new Date(str), 
              mnth = ("0" + (date.getMonth() + 1)).slice(-2),
              day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }
  return (
      <>
        <div className='p-2 border colorazul HoySemanaMes' id='divhoy' onClick={ clickColor }>Hoy</div>
        <div className='p-2 border HoySemanaMes' id='divsemana' onClick={ clickColor }>Semana</div>
        <div className='p-2 border HoySemanaMes' id='divmes' onClick={ clickColor }>Mes</div>
        <div id="divFecha" className='border' onClick={ clickColorFecha }>
          <OverlayTrigger 
            trigger="click" 
            placement='bottom' 
            rootClose={true}
            overlay={popover}>
              <Button variant={fecha.colordiv}>
                <FontAwesomeIcon icon="fa-solid fa-calendar-days" size='1x' />
              </Button>
          </OverlayTrigger>
        </div>
      </>
  )
}
